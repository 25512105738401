@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/helvetica-neue-master/HelveticaNeue-Regular.otf');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/helvetica-neue-master/HelveticaNeue-Medium.otf');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/helvetica-neue-master/HelveticaNeue-Bold.otf');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }

  html,
  body,
  #__next {
    height: 100%;
  }
  body,
  #__next {
    display: flex;
    flex-direction: column;
  }

  main {
    flex: 1 0 auto;
  }

  .site-footer {
    flex-shrink: 0;
  }

  .pera-wallet-connect-modal {
    z-index: 99999;
  }
}
